import React from 'react'
import './Styles.css';

function Chat() {
  return (

    <>

    <div className='BgChatPg'>
    <div className="HeadChatPg"> Chat Now with your private experience manager </div>
    </div>


    </>




  )
}

export default Chat