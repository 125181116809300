import React from 'react'
import IconsAuraExp4 from './Images/IconsAuraExp4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard4() {
  return (
   

    <div className='ExpPgCard  '>
      
      <p className='ExpPgHExpName'> Pottery Workshop, Jaipur </p>
      
      <p className='ExpPgHCat'> Craft </p>
      <p className='ExpPgHCode'> AURAMUSE104 </p>

          

      <div className='IconsAuraExpDiv'>   <img className='IconsAuraExp' src={IconsAuraExp4} /> </div>
      
      <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        


    </div>



  )
}

export default ExpCard4