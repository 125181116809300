import React from 'react'

function Styles() {
  return (

    <div > Styles

    </div>


  )
}

export default Styles