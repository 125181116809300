import React from 'react'
import IconsAuraExp15 from './Images/IconsAuraExp15.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard15() {
  return (
    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Private Luxury camping in Thar Desert </p>
        
        <p className='ExpPgHCat'> Adventure </p>
        <p className='ExpPgHCode'> AURAMUSE115 </p>

        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp15} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>

  )
}

export default ExpCard15