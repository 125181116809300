import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

function ActivityPlusBt() {
  return (
    
    <>

            
            <FontAwesomeIcon className="ActivityPlusBt" icon={faCirclePlus}   style={{color: " ", }} />




    </>

  )
}

export default ActivityPlusBt