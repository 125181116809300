import React from 'react'
import IconsAuraExp10 from './Images/IconsAuraExp10.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard10() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Buddhist Monasteries Festivals </p>

        <p className='ExpPgHCat'> Festival </p>
        <p className='ExpPgHCode'> AURAMUSE110 </p>


      
        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp10} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        
    </div>




    
  )
}

export default ExpCard10