import React from 'react'
import IconsAuraExp1 from './Images/IconsAuraExp1.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard1() {
  return (

    <>

    <div className='ExpPgCard '> 
        <p className='ExpPgHExpName'> Samode Palace </p>
        
        <p className='ExpPgHCat'> Royal </p>
        <p className='ExpPgHCode'> AURAMUSE101 </p>

        {/* <ButtExpAll /> */}
        
        {/* <img className='' src="" /> */}
      
          
          

          <div className='IconsAuraExpDiv'> <img className='IconsAuraExp' src={IconsAuraExp1} /> </div>

          <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>

    </>
    
  )

}


export default ExpCard1

