import React from 'react'
import IconsAuraExp19 from './Images/IconsAuraExp19.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard19() {
  return (
    

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Learning and Performing Indian rituals </p>

        <p className='ExpPgHCat'> Culture </p>
        <p className='ExpPgHCode'> AURAMUSE119</p>


        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp19} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




  )
}

export default ExpCard19