import React from 'react'
import './Styles.css';
import ImgCompass from './Images/ImgCompass.png';
import ImgBindi from './Images/ImgBindi.png';

 
function About() {
  return (

    <>

    <div className='BgAboutPg'>

      <div className="HeadAboutPg">  An Aura Voyage is to awaken </div>
      <p className='AboutPgSubH'> since 2014</p>
   
    </div>

    <div className='BgSecIntro'> 
      <p className='BgSecIntroH1'> Aura Voyages is an Indian travel experience brand taking global voyagers to experience the original culture of India and the region. We are deeply invested and dipped in the details that make us so unique for your holiday. We are delighted to have you and deliver Indian experiences of ‘its own kind’.</p>
      <img className="ImgCompass" src={ImgCompass} alt="#" />
      <p className='BgSecIntroH2'> We take you to one of its kind voyàge to India soaked with pure essence of the Indian culture </p>

    </div>

    <div className='BgSecLogo'>
        
        <div className='ImgBindiDiv'> <img className='ImgBindi' src={ImgBindi} /> </div>

        <p className='LogoSecH'> 
           The Indian Bindi is part of Aura’s core identity and design that puts our culture at the center of the experience 
        </p>
        

    </div>

    <div className='BgSecLogoPattern'>

      <div className='Spacer1'> </div>

      <div> 
        <p className='LogoSecPatternH'>
        
          
        The Aura logo is a yin and yang of our culture shown through two main energies, a male and a female energy. The Indian Tika/Bindi representing the female aura, pride, beauty, aesthetics, divinity, third eye and the enclosed sun shape formed by the bindis in the negative space. Sun is a male representation of energy and considered to be the nourshing male entity. While the female that takes care through out the life.
        
        <br />

        Its not just a symbol, its a living example of how we have organised our culture and values in our lives. The wonder is to see these deep ethos wherever you go in the region. You will find some relation everywhere not just with the medieval past and modern times, but from the earlier times ever known to man.
        </p>
      </div>

    </div>

    <div className='SecAuraOffers'>
        
        <p className='SecAuraOffersH1'> 
          Our Voyages get you to the other side of the wonder
        </p> 
        <p  className='SecAuraOffersH2'> 
          We bring the rich wonders across the region with support from select and standardised experience partners. Aura MuseTM enriches all such experiences with its own investment, management, supervision and delivery to ensure a consistent promised experience.
        </p>

    </div>

    <div > </div>

    </>




  )
}

export default About