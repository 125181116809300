import React from 'react'
import './Styles.css';


function EnquireForm1() {
  return (

    <>

  
    <div className='BgEnquiryPg'> 
      <div className='OnePxDiv'> </div>
      <div className="HeadEnquiryPg"> Set forth on your Aura Voyage  </div>
         
    </div>
    

    <div className='EnqFormWid1Div'> 
        <div className='EnqFormWid1'> 

            {/* <div className="HeadContactPg"> Set forth on your Aura Voyage  </div> */}
            <div className="HeadFormDiv"> Send us your basic details and your enquiry   </div>
            
            <form className='EnquiryFormStyle' >

              <label className='EnquiryLabel' >  Full Name  </label>
              <input className='EnquiryInput' type="text" name="fullname"  />
               <br />

              <label className='EnquiryLabel'>   Mobile   
               {/* <input type="text" name="country-code"  />  */}      
              </label> 

              <input className='EnquiryInput' type="text" name="mobile"  /> 
              <br />

              <label className='EnquiryLabel'>   Email    </label> 
              <input className='EnquiryInput' type="text" name="email"  />
              <br />

              <label className='EnquiryLabel'>   Country    </label> 
              <input className='EnquiryInput' type="text" name="country"  />
              <br />

              <label className='EnquiryLabel'>  Are you looking for an Aura Voyage? Tell us more.. </label> 
              <div className='EnquiryInputMsgDiv'>  
                {/* <input className='EnquiryInputMsg' type="textarea" name="message"  />  */}
                <textarea className='EnquiryInputMsg'
                  // value={this.state.textAreaValue}
                  // onChange={this.handleChange}
                  // rows={10}
                  // cols={40}
                />

              </div>

              {/* <label className='EnquiryLabelSubmit'> Submit <input type="submit"> </input></label> */}

            </form>
            <div>  <a href="#"> <div className='ButtonEnquiry'>  Submit  </div> </a> </div>
           

        </div>
      </div>

    </>




  )
}

export default EnquireForm1