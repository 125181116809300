import React from 'react'
import IconsAuraExp6 from './Images/IconsAuraExp6.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard6() {
  return (
    

    <div className='ExpPgCard  '>
          
        <p className='ExpPgHExpName'> Yoga & Meditation </p>

        <p className='ExpPgHCat'> Wellness </p>
        <p className='ExpPgHCode'> AURAMUSE106 </p>

        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp6} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




  )
}

export default ExpCard6