// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';


 export const SidebarData = [
    {
        title: 'About',
        path: '/about-aura',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    {
        title: 'Aura Muse',
        path: '/aura-muse',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },
    {
        title: ' Abled Voyages',
        path: '/abled',
        // icon: <IoIcons.IoIosAlert /> ,
        cName: 'nav-text',
    },
    {
        title: 'Himalayan Voyages',
        path: '/himalayan',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    {
        title: ' Be Responsible ',
        path: '/be-responsible',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    // {
    //     title: 'Blogs',
    //     path: '/blogs',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },
    // {
    //     title: 'Culture',
    //     path: '/culture',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },
    // {
    //     title: 'Affiliation ',
    //     path: '/affiliation',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },

    // {
    //     title: 'Team',
    //     path: '/team',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },

    {
        title: 'Enquire',
        path: '/enquire',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    {
        title: 'Contact',
        path: '/contact',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    
]



