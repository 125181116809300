import React from 'react'
import IconsAuraExp11 from './Images/IconsAuraExp11.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard11() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Explore on Cycles </p>

        <p className='ExpPgHCat'> Adventure </p>
        <p className='ExpPgHCode'> AURAMUSE111 </p>


        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp11} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




    
  )
}

export default ExpCard11