import React from 'react'
import IconsAuraExp9 from './Images/IconsAuraExp9.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard9() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Himalayan Toy Train </p>

        <p className='ExpPgHCat'> Heritage </p> 
        <p className='ExpPgHCode'> AURAMUSE109 </p>

        

          <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp9} /> </div>

          <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




    
  )
}

export default ExpCard9