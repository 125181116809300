import React from 'react'
import './Styles.css';
import ImgLogoResp1 from './Images/BeRespArtwork.png';
import ImgLogoResp2 from './Images/ImgLogoResp2.jpg';


function BeResponsiblePg() {
  return (


        <>

        <div className='BgRespPg'>
        <div className="HeadRespPg"> The social side of Aura spreads Aura to other lives </div>
        <div className="SubHeadRespPg"> Responsible Aura Voyages </div>
        </div>

        <div className='SecRespIntro'> 
            <div className="TextDiv">
                <p className='TextRespBanner'>

                    Aura has been working with natives which helps make a better delivery of our rich & different experiences and helps them feel connected to the entire travel economy. Not only that, we run campaigns from time-to-time in different regions and areas which help bring change in the livelihood, environment and the future of the diverse India you visit. Each dollar you spend gets you some good. You can also join with some extra hands and work with us every year.  

                </p>
                <img className='ImgLogoResp2'  src={ImgLogoResp2}  />
          
            </div>

             <img className='ImgLogoResp1' src={ImgLogoResp1}   />

        </div>

        </>


)
}

export default BeResponsiblePg