import React from 'react'
import './Styles.css';
import ImgLogoHim from './Images/ImgLogoHim.svg';
import CardHim1 from './Images/CardHim1.png';
import CardHim2 from './Images/CardHim2.png';
import CardHim3 from './Images/CardHim3.png';
import CardHim4 from './Images/CardHim4.png';


function HimalayanPg() {
  return (    
    <>

    <div className='BgHimalayanPg'>
        <div className="HeadHimalayanPg"> The Himalayan Family of Aura Voyages </div>
        <div className="SubHeadHimalayanPg"> Himalayan Voyages </div>
    </div>


    <div className='SecHimIntro'> 
        <div className="TextDiv">
        <p className='TextHimBanner'>
            Access far flung regions of Himachal and the Himalayas with us. A small mountain state that has a lot to offer – mountain culture,  traditions, unique religious festivals, unique temple architecture, nature at its best, pristine villages, British colonial memories, Buddhist monasteries and much more
        </p>
        <p className='LinkHimBanner'> 
            <div> <a href="http://www.himalayanvoyages.com"> HimalayanVoyages.com </a> </div> 
        </p>
        </div>

        <img className='ImgLogoHim' src={ImgLogoHim} />
    
    </div>


     <div className='AllCardsHim'> 

          <div className='CardHim '> 
                <p className='CardHead'> As locals, we know Himalayas by Foot </p>
                <div className='CardHimImgDiv'>  <img className='CardHimImg' src={CardHim1} /> </div>
          </div>

          <div className='CardHim '> 
                <p className='CardHead'> Himalayan Know-How of more than 15 years </p>
                <div className='CardHimImgDiv'> <img className='CardHimImg' src={CardHim2} /> </div>
          </div>

          <div className='CardHim '> 
                <p className='CardHead'> Experience Himalayan with Minimum Negative Environmental Impact </p>
                <div className='CardHimImgDiv'> <img className='CardHimImg' src={CardHim3} /> </div>
          </div>

          <div className='CardHim '> 
                <p className='CardHead'> Customised and Extensive Experience Planning on each trip executed  </p>
                <div className='CardHimImgDiv'>  <img className='CardHimImg' src={CardHim4} /> </div>
          </div>



      </div>



    </>
    
  )
}

export default HimalayanPg