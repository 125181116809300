import React from 'react'
import IconsAuraExp21 from './Images/IconsAuraExp21.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard21() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Indian Classical Dance & Music </p>

        <p className='ExpPgHCat'> Music & Dance </p> 
        <p className='ExpPgHCode'> AURAMUSE121 </p>

        

          <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp21} /> </div>

          <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




    
  )
}

export default ExpCard21