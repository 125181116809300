import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronLeft} from '@fortawesome/free-solid-svg-icons';
import '../Styles.css';
import ActivityPlusBt from './ActivityPlusBt';


function ExpDetailPg1() {
  return (

    <> 
    <div className='spacer'> </div>
    <div className='ExpBoxDet'> 

      <FontAwesomeIcon className='FaIconCircExpDet' icon={faCircleChevronLeft} size="xl" style={{color: " ", }} />

      <p className='ExpTitle'> Village Experience at Rohet Shahpura Bagh </p>
      <p className='ExpCat'> Rural</p> 
      
       
      
        <div className='ExpPgDetBan1'>
          <img className='' src="" />
        </div>


        <div className='sec'>

          <p className='ExpCat'> Activities Available</p>
          
           <div className='ExpAvailCardsCont'> 
             <div className='ExpAvailCard'> <ActivityPlusBt /> <p className='ActivityName'> Meet Locals </p> </div>
             <div className='ExpAvailCard'> <ActivityPlusBt /> <p className='ActivityName'> Community Chores </p> </div>
             <div className='ExpAvailCard'> <ActivityPlusBt />  <p className='ActivityName'> Folk Singing </p></div>
             <div className='ExpAvailCard'> <ActivityPlusBt /> <p className='ActivityName'> Living Farm Life </p> </div>
             <div className='ExpAvailCard'> <ActivityPlusBt /> <p className='ActivityName'> Milking </p> </div>
           </div>
        
        </div>




        <div className='sec'>

          <p className='ExpCat'> Curators </p> 

          <div className='ExpAvailCardsCont'> 

             <div className='ExpAvailCard '> <p className='CuratorName'> Soumya Parekh </p> </div>

             <div className='ExpAvailCard '> <p className='CuratorName'> Adam Frank  </p> </div>
         

           </div>

        
        </div>

        <div className='sec-notes'>

           <p className='ExpCat'> How to Plan your Aura Voyage elements </p>

          <div className='PlanSubhead'>

            - Choose your experience and click on the enquire button to start a communication with our experience curator, 
            who in another few days will help you finalise your Aura Muse activities in the Indian subcontinent. 


          </div> 
          <div className='PlanSubhead'>

            - These elements are all curated by aura Muse team and the partners who are closely operating these facilities or experiences.
          
          </div> 

        </div>
      



    </div>

    </>
    
  )
}

export default ExpDetailPg1