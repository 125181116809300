import React from 'react'

function Login() {
  return (
    <div className="login">

        <h1> Login </h1>
      
    </div>
  )
}


export default Login
