import React from 'react'
import './Styles.css'
import { Link } from 'react-router-dom';
import img101 from "./Images/AbledSecHomeImg101.png";
import img102 from "./Images/AbledSecHomeImg102.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';


function AbledSecHome() {
  return (
    <> 

        
            <div className='BgAbledSecHome'> 
                <div className='BoxAbledSecHome'> 
                    <div className='HeadAbledSecHome'>
                            Time to leave home
                    </div>
                    <div> <img className="AbledImg101" src={img101} alt="" /> </div>
                    <div> <img className="AbledImg102" src={img102}  alt="" /> </div>

                    <Link to='/abled-traveller'> 
                    <div className='BtAbledSecHome'> Abled Travellers Program  
                    <FontAwesomeIcon className='widthFaIcon' icon={faCircleChevronRight} size="xl" style={{color: "#d1181f", }} />
                     
                    </div>
                    </Link>
                     

                </div>            


            </div>
        

    </>
  )
}

export default AbledSecHome