import React from 'react'
import './Styles.css'
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';


function ExpSectionHome() {
  return (

    <> 
    <div className="BgExpSecHome">
      <div className="HeadExpSecHome"> Experience first hand what we always find intriguing  </div>

        <Link to="/experiences">  
        <div className=" BtExpAll"> 
            Explore 
          <FontAwesomeIcon className='widthFaIconExpAll' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        </div>
        </Link>

    </div>
    </>
  )
}

export default ExpSectionHome