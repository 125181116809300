import React from 'react'
import ExpSectionHome from '../Experiences/ExpSectionHome'; 
import AbledSecHome from '../AbledTraveller/AbledSecHome';
import '../../App.css';
import "./Styles.css";
import HimalayanSecHome from '../Himalayan/HimalayanSecHome';
import Footer from '../../components/Footer/Footer';
// import Img001 from './Images/HomePageBanner.png';



function Home() {
  return (
    <>

      
      <div className="backgHomeBanner">
        {/* <img className='Img001' src=''  />  */}
      
        <p className="headingHome"> Dive into the Voyage of an Ancient Civilization </p>

      </div>
      
     
      
  
    
    <ExpSectionHome />
    <AbledSecHome />
    <HimalayanSecHome />
    
   
  

    {/* <Subscribe /> */}
    
    </>
  )
}



export default Home
