
import React from 'react'
import logo from '../logo/aura-horiz-logo.svg';
import '../logo/Styles.css'
import './Styles.css'
import NavbarTop from '../Navbar/NavbarTop';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';



function AuraAppHeader() {
  return (

    <>

        

        <div className="AuraAppHeader"> <Link to='/' > <img className="aura-logo" src={logo} alt="aura logo" />  </Link> </div>  
        <div>  <NavbarTop /> <Navbar /> </div>      
          


    </>
    
  )
}

export default AuraAppHeader






          
          