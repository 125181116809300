import React from 'react'
import IconsAuraExp3 from './Images/IconsAuraExp3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard3() {
  return (
    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Shivratri in Mandi (Himalayan) </p>
        
        <p className='ExpPgHCat'> Festivals </p>
        <p className='ExpPgHCode'> AURAMUSE103 </p>

        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp3} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>

  )
}

export default ExpCard3