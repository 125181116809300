import React from 'react'

function Team() {
  return (
    <div className="team">

        <h1> Team </h1>
      
    </div>
  )
}



export default Team
