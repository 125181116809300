import React from 'react'
import IconsAuraExp2 from './Images/IconsAuraExp2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard2() {
  return (

    <div className='ExpPgCard  '>

        <p className='ExpPgHExpName'> Brihadisvara temple </p>
        
        <p className='ExpPgHCat'> Divine </p>
        <p className='ExpPgHCode'> AURAMUSE102 </p>


          <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp ' src={IconsAuraExp2} /> </div>



        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />


    </div>


    
  )

}


export default ExpCard2

