import React from 'react'
import IconsAuraExp16 from './Images/IconsAuraExp16.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard16() {
  return (
   

    <div className='ExpPgCard  '>
      
      <p className='ExpPgHExpName'> Ayurveda in Kerala </p>
      
      <p className='ExpPgHCat'> Wellness </p>
      <p className='ExpPgHCode'> AURAMUSE116 </p>

          

      <div className='IconsAuraExpDiv'>   <img className='IconsAuraExp' src={IconsAuraExp16} /> </div>
      
      <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        


    </div>



  )
}

export default ExpCard16