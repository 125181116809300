import React from 'react'
import IconsAuraExp23 from './Images/IconsAuraExp23.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard23() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> A Day with an Elephant </p>

        <p className='ExpPgHCat'> Nature </p>
        <p className='ExpPgHCode'> AURAMUSE123 </p>


        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp23} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




    
  )
}

export default ExpCard23