import React from 'react'
import './Styles.css'
import {Link} from 'react-router-dom';

import ExpCard1 from './ExpDemo/ExpCard1';
import ExpCard2 from './ExpDemo/ExpCard2';
import ExpCard3 from './ExpDemo/ExpCard3';
import ExpCard4 from './ExpDemo/ExpCard4';
import ExpCard5 from './ExpDemo/ExpCard5';
import ExpCard6 from './ExpDemo/ExpCard6';
import ExpCard7 from './ExpDemo/ExpCard7';
import ExpCard8 from './ExpDemo/ExpCard8';
import ExpCard9 from './ExpDemo/ExpCard9';
import ExpCard10 from './ExpDemo/ExpCard10';
import ExpCard11 from './ExpDemo/ExpCard11';
import ExpCard12 from './ExpDemo/ExpCard12';
import ExpCard13 from './ExpDemo/ExpCard13';
import ExpCard14 from './ExpDemo/ExpCard14';
import ExpCard15 from './ExpDemo/ExpCard15';
import ExpCard16 from './ExpDemo/ExpCard16';
import ExpCard17 from './ExpDemo/ExpCard17';
import ExpCard18 from './ExpDemo/ExpCard18';
import ExpCard19 from './ExpDemo/ExpCard19';
import ExpCard20 from './ExpDemo/ExpCard20';
import ExpCard21 from './ExpDemo/ExpCard21';
import ExpCard22 from './ExpDemo/ExpCard22';
import ExpCard23 from './ExpDemo/ExpCard23';
import ExpCard24 from './ExpDemo/ExpCard24';
import ExpCard25 from './ExpDemo/ExpCard25';



function ExperiencesPg() {
  return (
    <>
    
    <div className='BgExperiencesPg'>
      <div className="HeadExperiencesPg"> Experiences explored with love at Aura Muse&#8482; </div>
      <div className="SubHeadExperiencesPg"> Browse through the experiences in the Indian region we bring to you with our rich curation and consistently trusted partners since 2014.
        </div>
    


          <div className='ExpPgExCont'> 
            
            <Link to="#" >  <ExpCard1 /> </Link>
            <Link to="#" >   <ExpCard2 /> </Link>
            <Link to="#" >   <ExpCard3 /> </Link>
            <Link to="#" >  <ExpCard4 /> </Link>
            <Link to="#" >  <ExpCard5 /> </Link>
            <Link to="#" >  <ExpCard6 /> </Link>
            <Link to="#" >  <ExpCard7 /> </Link>
            <Link to="#" >  <ExpCard8 /> </Link>
            <Link to="#" >  <ExpCard9 /> </Link>
            <Link to="#" >  <ExpCard10 /> </Link>
            <Link to="#" >  <ExpCard11 /> </Link>
            <Link to="#" >  <ExpCard12 /> </Link>
            <Link to="#" >  <ExpCard13 /> </Link>
            <Link to="#" >  <ExpCard14 /> </Link>
            <Link to="#" >  <ExpCard15 /> </Link>
            <Link to="#" >  <ExpCard16 /> </Link>
            <Link to="#" >  <ExpCard17 /> </Link>
            <Link to="#" >  <ExpCard18 /> </Link>
            <Link to="#" >  <ExpCard19 /> </Link>
            <Link to="#" >  <ExpCard20 /> </Link>
            <Link to="#" >  <ExpCard21 /> </Link>
            <Link to="#" >  <ExpCard22 /> </Link>
            <Link to="#" >  <ExpCard23 /> </Link>
            <Link to="#" >  <ExpCard24 /> </Link>
            <Link to="#" >  <ExpCard25 /> </Link>


          </div>

      </div>

   

    </>
    
  )
  
}

export default ExperiencesPg