import React from 'react'
import IconsAuraExp17 from './Images/IconsAuraExp17.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';




function ExpCard17() {
  return (
    

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Hot Air Balloon Ride </p>

        <p className='ExpPgHCat'> Adventure </p>
        <p className='ExpPgHCode'> AURAMUSE117 </p>

        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp17} /> </div>

        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




  )
}

export default ExpCard17