import React from 'react'
import './Styles.css'
import img101 from "./Images/HimalayanSecHomeImg101.png";
import img102 from "./Images/HimalayanSecHomeImg102.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function HimalayanSecHome() {
  return (
    <> 

        
            <div className='BgHimalayanSecHome'> 
                <div className='BoxHimalayanSecHome'> 
                    <div className='HeadHimalayanSecHome'> 
                            Pahari Experiences by Natives from the Himalayas 
                    </div>
                    <div> <img className="Img101" src={img101} alt="" /> </div>
                    <div> <img className="Img102" src={img102} alt="" /> </div>

                    <Link to='/himalayan-voyages'> 
                    <div className='BtHimalayanSecHome'>  Himalayan Voyages  
                       <FontAwesomeIcon className='widthFaIcon' icon={faCircleChevronRight} size="xl" style={{color: "#d1181f", }} />
                    </div>
                    </Link>


                </div>            


            </div>
        

    </>
  )
}

export default HimalayanSecHome