import React from 'react'
import IconsAuraExp14 from './Images/IconsAuraExp14.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard14() {
  return (

    <div className='ExpPgCard  '>

        <p className='ExpPgHExpName'> Hand Block Printing Workshop  </p>
        
        <p className='ExpPgHCat'> Craft </p>
        <p className='ExpPgHCode'> AURAMUSE114 </p>


          <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp ' src={IconsAuraExp14} /> </div>



        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />


    </div>


    
  )

}


export default ExpCard14

