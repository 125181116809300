import React from 'react'
import IconsAuraExp20 from './Images/IconsAuraExp20.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleChevronRight} from '@fortawesome/free-solid-svg-icons';



function ExpCard20() {
  return (

    <div className='ExpPgCard  '>
      
        <p className='ExpPgHExpName'> Learn about Indian cooking </p>

        <p className='ExpPgHCat'> Cuisine </p>
        <p className='ExpPgHCode'> AURAMUSE120 </p>

        

        <div className='IconsAuraExpDiv'>  <img className='IconsAuraExp' src={IconsAuraExp20} /> </div>
        <FontAwesomeIcon className='FaIconCirc' icon={faCircleChevronRight} size="xl" style={{color: " ", }} />
        

    </div>




    
  )
}

export default ExpCard20